const listVideos = [
  {
    id: 1,
    name: 'Documentary "BoaMistura"',
    description: 'Produced by Blur Film & Monte Perdido',
    urlv: 'https://vimeo.com/658634127',
    videourls: ['https://vimeo.com/659448288'],
    img: 'trabajo1',
    stitle: 'Documentary "BoaMistura"',
    antetitulo: 'Produced by Blur Film & Monte Perdido'
  },
  {
    id: 2,
    name: 'Decathlon',
    description: 'Ogilvy & Mather',
    urlv: 'https://vimeo.com/676847574',
    videourls: ['https://vimeo.com/676847707'],
    img: 'trabajo11',
    stitle: 'Decathlon',
    antetitulo: 'Ogilvy & Mather'
  },
  {
    id: 3,
    name: 'Cancer de Mama Metastásico',
    description: 'Atrevia',
    urlv: 'https://vimeo.com/657957935',
    videourls: ['https://vimeo.com/657957935'],
    img: 'trabajo2',
    stitle: 'Cancer de Mama Metastásico',
    antetitulo: 'Atrevia'
  },
  {
    id: 4,
    name: 'Rolex Cool Hand, Spike Lee',
    description: 'Le Artisans',
    urlv: 'https://vimeo.com/658693080',
    videourls: ['https://vimeo.com/244710391'],
    nextVideoinfo: ['https://vimeo.com/408791016', 'Rolex Andrea Pirlo', 'Le Artisans'],
    img: 'trabajo3',
    stitle: 'Rolex Cool Hand, Spike Lee',
    antetitulo: 'Le Artisans'
  },
  {
    id: 5,
    name: 'Cruzcampo',
    description: 'Ogilvy & Mather',
    urlv: 'https://vimeo.com/658695280',
    videourls: ['https://vimeo.com/658694195'],
    img: 'trabajo4',
    stitle: 'Cruzcampo',
    antetitulo: 'Ogilvy & Mather'
  },
  {
    id: 6,
    name: 'Bernie Sanders',
    description: 'US Presidential Election 2020',
    urlv: 'https://vimeo.com/658696033',
    videourls: ['https://vimeo.com/407112129'],
    img: 'trabajo5',
    stitle: 'Bernie Sanders',
    antetitulo: 'US Presidential Election 2020'
  },
  {
    id: 7,
    name: 'Documentary "Silence Tears of the Midwife"',
    description: 'Produced by PrimeScape L.A',
    urlv: 'https://vimeo.com/659017850',
    videourls: ['https://vimeo.com/659421102'],
    img: 'trabajo6',
    stitle: 'Documentary "Silence Tears of the Midwife"',
    antetitulo: 'Produced by PrimeScape L.A',
    produced: 'One Breath & Midwife Vision',
  },
  {
    id: 8,
    folder: 7,
    name: 'Serie "La Reina del Pueblo"',
    description: 'Atresplayer',
    urlv: 'https://vimeo.com/659025095',
    videourls: ['https://vimeo.com/659022243'],
    img: 'trabajo7',
    stitle: 'Serie "La Reina del Pueblo"',
    antetitulo: 'Atresplayer',
    produced: 'Produced by Estela Films',
    gallery: ['1.jpeg','2.jpeg','3.jpeg','4.jpeg']
  },
  {
    id: 9,
    name: 'Renault FeliZiudad',
    description: 'Publicis',
    urlv: 'https://vimeo.com/659033125',
    videourls: ['https://vimeo.com/657967914'],
    img: 'trabajo8',
    stitle: 'Renault FeliZiudad',
    antetitulo: 'Publicis'
  },
  {
    id: 10,
    name: 'Reale Espanyol',
    description: 'Ogilvy & Mathers',
    urlv: 'https://vimeo.com/659047574',
    videourls: ['https://vimeo.com/659048055'],
    img: 'trabajo9',
    stitle: 'Reale Espanyol',
    antetitulo: 'Ogilvy & Mathers'
  },
  {
    id: 11,
    folder: 10,
    name: 'Time 2 Surrender',
    description: 'ShortFilm',
    urlv: 'https://vimeo.com/659053770',
    videourls: ['https://vimeo.com/263236238'],
    img: 'trabajo10',
    stitle: 'Time 2 Surrender',
    produced: 'Executive Producer | Spike Lee & Elvis Nolasco',
    extra1: 'Associate producer | Sonja Perryman & Yanko Perez',
    extra2: 'Produced by Kerry Mondragon',
    extra3: 'Photography by Salvador Bolivar',
    extra4: 'Edited by Noel Rayo Diz',
    extra5: 'Written & Directed by Elvis Nolasco',
    gallery: ['1.jpg']
  },
  {
    id: 12,
    name: 'Unidas Podemos',
    description: 'Estela Films',
    urlv: 'https://vimeo.com/676774469',
    videourls: ['https://vimeo.com/653381336'],
    img: 'trabajo12',
    stitle: 'Unidas Podemos - Elecciones C. de Madrid 2021',
    antetitulo: 'Estela Films'
  },
  {
    id: 13,
    folder: 13,
    name: 'Film "Nosotros"',
    description: 'Vermut Films | Nylon | DeVerbena',
    urlv: 'https://vimeo.com/676779305',
    videourls: ['https://vimeo.com/219771615'],
    img: 'trabajo13',
    stitle: 'Film "Nosotros"',
    produced: 'Produced by Vermut Films, Nylon & DeVerbena',
    extra1: 'Directed by: Felipe Vara de Rey',
    extra2: 'Produced by: Vermut Films, DeVerbena, Nylon',
    extra3: 'Written by: Hugo Mas and Felipe Vara de Rey',
    extra4: 'Editing: Noel Rayo',
    extra5: 'Photography by: Roberto San Eugenio and Fede Cesca',
    extra6: 'Music: Víctor Magro',
    gallery: ['2.png', '1.jpg']
  },
  {
    id: 14,
    name: 'TurEspaña',
    description: 'Ogilvy',
    urlv: 'https://vimeo.com/676824636',
    videourls: ['https://vimeo.com/225566622','https://vimeo.com/225566509', 'https://vimeo.com/225566384', 'https://vimeo.com/225566276'],
    img: 'trabajo14',
    stitle: 'TurEspaña',
    antetitulo: 'Ogilvy & Mather'
  },
  {
    id: 15,
    name: 'Budweiser',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/676829511',
    videourls: ['https://vimeo.com/255182750'],
    img: 'trabajo15',
    stitle: 'Budweiser',
    antetitulo: 'La Despensa'
  },
  {
    id: 16,
    name: 'Democrazy',
    description: 'Middle Class',
    urlv: 'https://vimeo.com/676878005',
    videourls: ['https://vimeo.com/263556670'],
    img: 'trabajo16',
    stitle: 'Democrazy',
    antetitulo: 'Middle Class'
  },
  {
    id: 17,
    folder: 1,
    name: 'Renault',
    description: 'Prodigious',
    urlv: 'https://vimeo.com/676882222',
    videourls: ['https://vimeo.com/676882222'],
    img: 'trabajo17',
    stitle: 'Renault',
    antetitulo: 'Prodigious'
  },
  {
    id: 18,
    folder: 1,
    name: 'Befeeather',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/679464520',
    videourls: ['https://vimeo.com/255182920'],
    img: 'trabajo18',
    stitle: 'Befeeather',
    antetitulo: 'La Despensa'
  },
  {
    id: 19,
    folder: 19,
    name: 'Film "See You Yesterday"',
    description: 'Netflix',
    urlv: 'https://vimeo.com/680392046',
    videourls: ['https://vimeo.com/298013649', 'https://www.youtube.com/watch?v=8MVRWQ1PnMo&t=35s'],
    img: 'trabajo19',
    stitle: 'Film "See You Yesterday"',
    antetitulo: 'Netflix',
    produced: 'Produced by Spike Lee',
    extra1: 'This Short Film was produced by Spike Lee and directed by Stefon Bristol and edited by Noel Rayo.',
    extra2: 'After a rally of awards and festivals, Netflix created the feature film with an extended version of the same script. Also produced by Spike Lee.',
    gallery: ['3.jpeg', '2.png']
  },
  {
    id: 20,
    name: 'Serie "Antes"',
    description: 'Estela Film & Pólvora Film',
    urlv: 'https://vimeo.com/676867100',
    videourls: ['https://vimeo.com/255182920'],
    img: 'trabajo20',
    stitle: 'Serie "Antes"',
    antetitulo: 'Estela Film & Pólvora Film'
  },
  {
    id: 21,
    name: 'LP',
    description: 'ShortFilm',
    urlv: 'https://vimeo.com/679468576',
    videourls: ['https://vimeo.com/442274904'],
    img: 'trabajo21',
    stitle: 'LP',
    antetitulo: 'ShortFilm'
  },
  {
    id: 22,
    name: 'Unidas Podemos',
    description: 'Estela Films',
    urlv: 'https://vimeo.com/679471286',
    videourls: ['https://vimeo.com/399401813'],
    img: 'trabajo22',
    stitle: 'Unidas Podemos',
    antetitulo: 'Estela Films'
  },
  {
    id: 23,
    name: 'Secforce',
    description: 'The Woork Co',
    urlv: 'https://vimeo.com/679472619',
    videourls: ['https://vimeo.com/679472974'],
    img: 'trabajo23',
    stitle: 'Secforce',
    antetitulo: 'The Woork Co'
  },
  {
    id: 24,
    name: 'La Casera',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/679477621',
    videourls: ['https://vimeo.com/679477468'],
    img: 'trabajo24',
    stitle: 'La Casera',
    antetitulo: 'La Despensa'
  },
  {
    id: 25,
    name: 'Old Spice',
    description: 'Leo Burnett',
    urlv: 'https://vimeo.com/679729708',
    videourls: ['https://vimeo.com/653380505'],
    img: 'trabajo25',
    stitle: 'Old Spice',
    antetitulo: 'Leo Burnett'
  },
  {
    id: 26,
    name: 'Absolute',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/679734306',
    videourls: ['https://vimeo.com/255182297'],
    img: 'trabajo26',
    stitle: 'Absolute',
    antetitulo: 'La Despensa'
  },
  {
    id: 27,
    name: 'Visa',
    description: 'Prodigious',
    urlv: 'https://vimeo.com/679737770',
    videourls: ['https://vimeo.com/679739072'],
    img: 'trabajo27',
    stitle: 'Visa',
    antetitulo: 'Prodigious'
  },
  {
    id: 28,
    name: 'EDP',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/679741042',
    videourls: ['https://vimeo.com/255183117'],
    img: 'trabajo28',
    stitle: 'EDP',
    antetitulo: 'La Despensa'
  },
  {
    id: 29,
    name: 'Unidas Podemos',
    description: 'Estela Films',
    urlv: 'https://vimeo.com/679743031',
    videourls: ['https://vimeo.com/399402121'],
    img: 'trabajo29',
    stitle: 'Unidas Podemos',
    antetitulo: 'Estela Films'
  },
  /*--------------------------------------------------------*/ 
  {
    id: 30,
    name: 'Serie “#Primero”',
    description: 'Albiñana Films',
    urlv: 'https://vimeo.com/683948714',
    videourls: ['https://vimeo.com/683949070'],
    img: 'trabajo30',
    stitle: 'Serie “#Primero”',
    antetitulo: 'Albiñana Films'
  },
  {
    id: 31,
    name: 'ShortFilm “Otis”',
    description: '',
    urlv: 'https://vimeo.com/683956489',
    videourls: ['https://vimeo.com/298013440'],
    img: 'trabajo31',
    stitle: 'ShortFilm “Otis”',
    antetitulo: ''
  },
  {
    id: 32,
    name: 'EDP',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/683960500',
    videourls: ['https://vimeo.com/683960870'],
    img: 'trabajo32',
    stitle: 'EDP',
    antetitulo: 'La Despensa'
  },
  {
    id: 33,
    name: 'IconLab',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/683963491',
    videourls: ['https://vimeo.com/683963444'],
    img: 'trabajo33',
    stitle: 'IconLab',
    antetitulo: 'La Despensa'
  },
  {
    id: 34,
    name: 'Ruavieja',
    description: 'Leo Burnett',
    urlv: 'https://vimeo.com/683966550',
    videourls: ['https://vimeo.com/394329108'],
    img: 'trabajo34',
    stitle: 'Ruavieja',
    antetitulo: 'Leo Burnett'
  },
  {
    id: 35,
    name: 'Lam Rim',
    description: 'I Cry',
    urlv: 'https://vimeo.com/683975203',
    videourls: ['https://vimeo.com/51075585'],
    img: 'trabajo35',
    stitle: 'Lam Rim',
    antetitulo: 'I Cry'
  },
  {
    id: 36,
    name: 'Vandal',
    description: 'Shortfilm',
    urlv: 'https://vimeo.com/684962193',
    videourls: ['https://vimeo.com/144431409'],
    img: 'trabajo36',
    stitle: 'Vandal',
    antetitulo: 'Shortfilm'
  },
  {
    id: 37,
    name: 'Deliveroo',
    description: 'Shooting Arts',
    urlv: 'https://vimeo.com/684964633',
    videourls: ['https://vimeo.com/250616011'],
    img: 'trabajo37',
    stitle: 'Deliveroo',
    antetitulo: 'Shooting Arts'
  },
  {
    id: 38,
    name: 'Nosotros',
    description: 'Dremen',
    urlv: 'https://vimeo.com/684969672',
    videourls: ['https://vimeo.com/207830800'],
    img: 'trabajo38',
    stitle: 'Nosotros',
    antetitulo: 'Dremen'
  },
  {
    id: 39,
    name: 'Medicos del Mundo',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/684970867',
    videourls: ['https://vimeo.com/657968153'],
    img: 'trabajo39',
    stitle: 'Medicos del Mundo',
    antetitulo: 'La Despensa'
  },
  {
    id: 40,
    name: 'Rigamo',
    description: 'ShortFilm',
    urlv: 'https://vimeo.com/684972439',
    videourls: ['https://vimeo.com/150926717'],
    img: 'trabajo40',
    stitle: 'Rigamo',
    antetitulo: 'ShortFilm'
  },
  {
    id: 41,
    name: 'Tadeo Jones',
    description: 'Nailon',
    urlv: 'https://vimeo.com/684976744',
    videourls: ['https://vimeo.com/226564232'],
    img: 'trabajo41',
    stitle: 'Tadeo Jones',
    antetitulo: 'Nailon'
  },
  {
    id: 42,
    name: 'Unidas Podemos',
    description: 'Estela Films',
    urlv: 'https://vimeo.com/684980156',
    videourls: ['https://vimeo.com/171324916'],
    img: 'trabajo42',
    stitle: 'Unidas Podemos',
    antetitulo: 'Estela Films'
  },
  {
    id: 43,
    name: 'Nivea',
    description: 'CPWorks',
    urlv: 'https://vimeo.com/684981077',
    videourls: ['https://vimeo.com/210805135'],
    img: 'trabajo43',
    stitle: 'Nivea',
    antetitulo: 'CPWorks'
  },
  {
    id: 44,
    name: 'Runner Icon',
    description: 'La Despensa',
    urlv: 'https://vimeo.com/684982445',
    videourls: ['https://vimeo.com/230800779'],
    img: 'trabajo44',
    stitle: 'Runner Icon',
    antetitulo: 'La Despensa'
  },
  {
    id: 45,
    name: 'Lam Rim',
    description: 'Feel Fine',
    urlv: 'https://vimeo.com/684984244',
    videourls: ['https://vimeo.com/41042977'],
    img: 'trabajo45',
    stitle: 'Lam Rim',
    antetitulo: 'Feel Fine'
  },
  {
    id: 46,
    name: 'Codere',
    description: 'SolWorks',
    urlv: 'https://vimeo.com/684985374',
    videourls: ['https://vimeo.com/210804754'],
    img: 'trabajo46',
    stitle: 'Codere',
    antetitulo: 'SolWorks'
  },
  {
    id: 47,
    name: 'Independencia',
    description: 'ShortFilm',
    urlv: 'https://vimeo.com/684986412',
    videourls: ['https://vimeo.com/24215615'],
    img: 'trabajo47',
    stitle: 'Independencia',
    antetitulo: 'ShortFilm'
  },
  {
    id: 48,
    name: 'Helios',
    description: 'Nylon',
    urlv: 'https://vimeo.com/684990637',
    videourls: ['https://vimeo.com/204225467'],
    img: 'trabajo48',
    stitle: 'Helios',
    antetitulo: 'Nylon'
  },
  {
    id: 49,
    name: 'Blanca Suarez',
    description: 'Revista Don',
    urlv: 'https://vimeo.com/684991838',
    videourls: ['https://vimeo.com/115942272'],
    img: 'trabajo49',
    stitle: 'Blanca Suarez',
    antetitulo: 'Revista Don'
  },
  {
    id: 50,
    name: 'Solos',
    description: 'ShortFilm',
    urlv: 'https://vimeo.com/684993003',
    videourls: ['https://vimeo.com/57230549'],
    img: 'trabajo50',
    stitle: 'Solos',
    antetitulo: 'ShortFilm'
  },
  {
    id: 51,
    name: 'Black StartUp',
    description: '',
    urlv: 'https://vimeo.com/683971075',
    videourls: ['https://vimeo.com/112336540'],
    img: 'trabajo51',
    stitle: 'Black StartUp',
    antetitulo: ''
  },
  {
    id: 52,
    name: 'Alhambra',
    description: 'CPWorks',
    urlv: 'https://vimeo.com/684993870',
    videourls: ['https://vimeo.com/210805036'],
    img: 'trabajo52',
    stitle: 'Alhambra',
    antetitulo: 'CPWorks'
  },
   {
    id: 53,
    name: 'BBVA',
    description: 'ContestArea',
    urlv: 'https://vimeo.com/685542469',
    videourls: ['https://vimeo.com/57612500'],
    img: 'trabajo53',
    stitle: 'BBVA',
    antetitulo: 'ContestArea'
  },
  
];

export default listVideos;